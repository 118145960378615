*,
body {
    margin: 0;
}

body,
html {
    height: 100%;
}

:root {
    --fw-reg: 300;
    --fw-bold: 900;
    --clr-pi: rgb(230, 32, 60);
    --clr-pi-leaf: rgb(99, 186, 70);
    --clr-main: rgb(255, 255, 255);
    --clr-dark: rgb(0, 0, 0);
    --clr-accent-lite: rgba(255, 238, 0, 0.7);
    --fs-h1: 3rem;
    --fs-h2: 2rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;
    --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25), 0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

.App {
    margin: 0;
}

/* Navbar */
.navbar__body {
    padding: 0.3rem;
    display: flex;
    justify-content: space-between;
}

.navbar-item {
    display: inline-block;
    height: 100%;
}

.navbar__item--menu {
    display: flex;
}

.navbar__item {
    position: relative;
}

.navbar-item__logo {
    max-width: 40px;
}

.pi__section {
    width: 100%;
    margin: 0.5rem 0;
}

.pi__section--card {
    margin: 2rem 0;
    padding: 0.7rem;
}

/* Login / Register */

.userAuth__body {
    position: absolute;
    right: 0;
    margin-right: 1rem;
    z-index: 1;
    top: 2rem;
    min-width: 250px;
}

 .form-text-muted {
    font-size: 0.75rem;
    color: var(--clr-pi) ;
}

.userAuth__container {
    padding: 1rem;
}

.userAuth__inputgroup--validation {
    flex-direction: column;
}

.userAuth__inputgroup--validation .userAuth__formcontrol {
    width: 100%;
}

.form-text-muted {
    color: var(--clr-pi);
}

.userAuth__registerDisabled {
    color: var(--clr-pi);
}

/* PiTim Blacklist */

.blacklist__list {
    margin: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

.websiteItem__container {
    display: flex;
}

.websiteItem__container + .websiteItem__container {
    margin-left: 0.5rem;
}

.websiteItem__button--edit {
    padding: 0.1rem 0.65rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.websiteItem__button--delete {
    font-size: 0.7rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.listItem__container {
    display: flex;
}

.blacklist__input.invalid_input {
    border: 1px solid var(--clr-pi);
}

/* PiTim Dashboard */

.pitim__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto 2rem;
}

.project__header {
    text-align: center;
    width: 95%;
    margin: 2rem 0;
}

.project__logo {
    width: 33%;
}

.project__title {
    font-size: 1.4rem;
    margin-top: 5px;
}

.project__description {
    font-size: 1rem;
}

.pi__section--title {
    font-size: 1.4rem;
    line-height: 1.4rem;
}

@media (min-width: 800px) {
    .project__logo {
        width: 10%;
    }

    .project__title {
        font-size: 2rem;
        margin-top: 1rem;
    }

    .project__description {
        font-size: 1.5rem;
    }
}

/* Pi form */

.form__section {
    margin: 5px 0;
}

.form__select {
    font-size: 0.8rem;
}

.pi__form--button {
    margin: 15px 0 0 0;
}

@media (min-width: 800px) {
    .pi__form {
        display: flex;
        align-items: center;
    }

    .form__group {
        display: flex;
    }

    .form__section {
        margin: 0 1rem;
        display: flex;
        align-items: center;
    }

    .form__section--model {
        width: 400px;
    }

    .form__section--currency {
        width: 155px;
    }

    .form__select {
        font-size: 1rem;
    }

    .pi__form--button {
        margin: 0 0 0 10px;
    }
}

.form__label {
    margin-right: 10px;
    margin-bottom: 0;
}

/* pi list */

.piList__body {
    width: 100%;
}

.pi__table--row,
.pi__table--head {
    font-size: 0.8rem;
}

.piList__header {
    text-align: center;
    position: relative;
}

.piList__header--refresh__btn {
    position: absolute;
    top: 0;
    right: 0;
}

.re-scrape__text {
    display: none;
}

.piList__header--title {
    font-weight: bold;
    font-size: 1.3rem;
}

.piList__table {
    margin-top: 1rem;
    text-align: left;
}

.table-data--currencies {
    max-width: 100px;
}

.table-data__button-group {
    display: flex;
}

.table-data__button {
    flex: 1;
}

.fontAwesomeIcon {
    font-size: 0.8rem;
}

.table-data__button {
    padding: 0;
}

.table-data__button--help {
    position: relative;
}

.helpIcon {
    position: absolute;
    right: 3px;
    top: 3px;
}

@media (min-width: 800px) {
    .pi__table--row,
    .pi__table--head {
        font-size: 1rem;
    }

    .table-data__button {
        padding: 0.375rem 0.75rem;
    }

    .re-scrape__icon--text {
        display: none;
    }

    .re-scrape__text {
        display: inline-block;
        margin-left: 5px;
    }

    .fontAwesomeIcon {
        font-size: 1rem;
    }
}

/* Project Footer */
.project__footer {
    width: 100%;
    display: flex;
}

.project__footer .saveSettings__btn {
    flex-grow: 10;
}

:disabled {
    background-color: lightgrey;
}

.disable_save_override {
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
}

.disable_save_override--list-group {
    border: none;
    text-align: left;
}

.project-timeline--item.done {
    color: var(--clr-pi);
    text-decoration: line-through;
}

@media (min-width: 800px) {
    .disable_save_override {
        margin-top: 1rem;
        width: 60%;
    }
}

.svg-inline--fa {
    pointer-events: none;
}